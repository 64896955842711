import monaco from 'monaco-js';

import {getLangFromExtension} from 'o365.vue.modules.monacoHelpers.ts';

let control = null;
document.body.addEventListener('o365.theme.changed', (event) => { if(control) control.updateTheme() })


export default function getCompare(pOptions:any){

    if(!pOptions.element) return;

    if(control && pOptions.element !== control.element){
        control.dispose();
        control = null;
    } 

    if(!control) control = new MonacoControl(pOptions);
    control.setModels(pOptions);
    return control;
}


class MonacoControl{
    editor:any;
    models:Map<string,any> = new Map();

    element:any;

    keepModelsAfterDispose:boolean = false;

    constructor(pOptions){
        this.element = pOptions.element;
        this.editor =  monaco.editor.createDiffEditor(pOptions.element, {
            readOnly: true,
 
            ignoreTrimWhitespace: true, 
            renderWhitespace: 'all' ,
            minimap: false,

            tabSize: 4,
            detectIndentation: false,
            
            automaticLayout: true
        });
        this.updateTheme();

        window['diffEditor'] =  this.editor;
    }

    setModels(pOptions:any){
       // this.updateTheme();
        if(!pOptions.models.source1.fileName) return;
        if(!pOptions.models.source2.fileName) return;
        this.editor.setModel ({
            original: this.getModel(pOptions.models.source1??""),
            modified: this.getModel(pOptions.models.source2??"")
        });
    }

    getModel(pModel:any){
        const vLanguage = getLangFromExtension(pModel.fileName);
        if(vLanguage === "json" && pModel.code){
            const vTmp = JSON.parse(pModel.code);
            pModel.code = JSON.stringify(vTmp,null, "\t");
        }
        if(!this.models.get(pModel.id??pModel.fileName)){
         
           
            this.models.set(pModel.id??pModel.fileName, monaco.editor.createModel(pModel.code, vLanguage))
        }else{
            const vModel = this.models.get(pModel.id??pModel.fileName);

            vModel.setValue(pModel.code);
        }
        
       

        return this.models.get(pModel.id??pModel.fileName);
    }

    dispose(){
        //monaco.editor.getModels().forEach((model: { dispose: () => any; }) => model.dispose());
        this.editor.dispose();

        this.models.clear();
        

    }

    updateTheme(){
        const isDark = document.documentElement.getAttribute("data-bs-theme") === "dark";
    
        this.editor.updateOptions({
            theme: isDark?"vs-dark":"vs"
        });
    }
}